<template>
  <div class="app-container" :style="{
    width: screenWidth + 'px',
    height: screenHeight + 'px',
  }">
    <div class="content">
      <div>
        <h1 class="logo">欢迎来到叶记时光</h1>
        <h1 class="notice">应用审核中...</h1>
      </div>

      <div class="wrapper-qrcode">
        <a href="https://www.mxz.net.cn/" target="_blank"><img src="./assets/qrcode.png" /></a>
      </div>

      <div class="copyright">
        <p class="icp">天津市武清区叶记时光软件开发工作室</p>
        <p class="icp">Copyright&copy;2024-2025</p>
        <div class="icp_row">
          <a class="icp-link" href="https://beian.mps.gov.cn/#/query/webSearch?code=12011402001569" rel="noreferrer"
            target="_blank">
            <img src="./assets/safe.png" alt="icon" style="width: 16px; height: 16px; margin-right: 5px;">
            津公网安备12011402001569
          </a>
          <a class="icp-link" href="https://beian.miit.gov.cn" target="_blank" style="display: inline-flex; align-items: center;">
            津ICP备2024021043号
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './assets/css/reset.css'

export default {
  name: 'App',
  data() {
    return {
      screenWidth: null,
      screenHeight: null,
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth
    this.screenHeight = window.innerHeight

    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.screenHeight = window.innerHeight
    }
  },
}
</script>

<style>
.app-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  background: url(./assets/background_pc.png) no-repeat;
  background-position: center center;
  background-size: auto 100%;
}

.content {
  margin: 10px auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明背景 */
  padding: 10px;
  border-radius: 10px;
  height: 100%;
}

.wrapper-qrcode {
  margin-top: 100px;
}

.logo {
  font-size: 2em;
}

.notice {
  font-size: 1.5em;
  margin-top: 20px;
}

.copyright {
  margin-bottom: 0px;
}

.icp {
  flex-direction: row;
  margin-top: 5px;
  font-size: 0.5em;
}
.icp_row {
  flex-direction: row;
  margin-top: 5px;
  font-size: 0.5em;
  display: inline-flex; 
  align-items: center;
}

.icp-link {
  margin-left: 10px;
  color: white;
  display: inline-flex; 
  align-items: center;
}

@media screen and (max-width: 500px) {
  .app-container {
    background-position: 25% top;
  }

  .content {
    width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .app-container {
    background-size: 100% 100%;
  }

  .content {
    width: 60%;
  }
}
</style>
